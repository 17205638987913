@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* overflow: scroll !important; */
    padding: 0 !important;
  }
  ul,
  ol {
    list-style: revert;
    /* margin-left: 16px; */
  }

  a {
    text-decoration: revert;
    color: revert;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.App {
  background-color: #f7f9fc;
  min-height: 100vh;
  /* padding-bottom: 50px; */
}

a,
.pointer {
  cursor: pointer !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Code to remove */

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.w-1\/3 {
  width: 33% !important;
}

.w-2\/3 {
  width: 67% !important;
}

.w-1\/4 {
  width: 25% !important;
}

.w-3\/4 {
  width: 75% !important;
}

.w-1\/5 {
  width: 20% !important;
}

.w-2\/5 {
  width: 40% !important;
}

.w-3\/5 {
  width: 60% !important;
}

.w-4\/5 {
  width: 80% !important;
}

.text-red-info {
  color: #f56a6a !important;
}

.text-yellow-info {
  color: #dad868 !important;
}
.text-orange-info {
  color: #d47900 !important;
}
.text-green-info {
  color: #56bf56 !important;
}

.items-start {
  align-items: start;
}

.flex-flow-wrap {
  flex-flow: wrap;
}

.compare-table tr {
  width: 100% !important;
  height: 4rem !important;
  display: inline-table !important;
}

.compare-table td:nth-child(1),
.compare-table td:nth-child(2) {
  width: 12% !important;
}
.compare-table td:nth-child(3),
.compare-table td:nth-child(4),
.compare-table td:nth-child(5),
.compare-table td:nth-child(6),
.compare-table td:nth-child(7) {
  width: 12% !important;
  text-align: center;
}

.compare-table td.longer-td {
  width: 38% !important;
}

.compare-table {
  height: 80vh;
  overflow: auto;
}

/* .compare-table th, */
.compare-table #summary > tr:nth-child(1){
  position: sticky;
  top: 0;
  height: 80px !important;
  background: white;
  width: 100% !important;
  display: inline-table !important;
  z-index: 25;
}
.compare-table #summary > tr:nth-child(2) {
  position: sticky;
  top: 80px;
  height: 80px !important;
  background: white;
  width: 100% !important;
  display: inline-table !important;
  z-index: 24;
}

.menu-dropdown {
  width: max-content !important;
}

.compare-chip {
  background: #56bf56 !important;
  color: white !important;
  padding: 2px 4px !important;
  height: auto !important;
}

.compare-text-wrap {
  white-space: break-spaces !important;
  word-break: break-word;
}

.compare-search-chip {
  background: rgba(255, 219, 124, 1) !important;
  padding: 2px 4px !important;
}

.-top-100 {
  top: -100px;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover,
.MuiIconButton-colorSecondary:hover {
  background-color: rgb(0 0 0 / 4%) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #1c1f2b !important;
}

.MuiCheckbox-colorSecondary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.bg-primary-light {
  background: rgba(102, 168, 169, 0.3) !important;
}
.bg-primary-lighter {
  background: rgba(167, 205, 206, 1) !important;
}

.bg-success {
  --tw-bg-opacity: 1;
  background-color: rgba(25, 167, 158, var(--tw-bg-opacity));
}
.ring-success {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(25, 167, 158, var(--tw-ring-opacity));
}
.text-success {
  --tw-text-opacity: 1;
  color: rgba(25, 167, 158, var(--tw-text-opacity));
}
.border-success {
  --tw-border-opacity: 1;
  border-color: rgba(25, 167, 158, var(--tw-border-opacity));
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.hover\:bg-success:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(25, 167, 158, var(--tw-bg-opacity));
}

.error-table table,
.error-table th,
.error-table td {
  border: 1px solid #dad868;
  padding: 8px;
  text-align: left;
}

.error-table td:nth-child(1) {
  width: 12% !important;
}
.error-table td:nth-child(2) {
  width: 25% !important;
}
.error-table td:nth-child(3) {
  width: 63% !important;
}

.bg-new-info {
  background: #99cae8;
}

.bg-green {
  background-color: #d9efef !important;
}

.bg-ice-darker {
  background-color: #c5e7e7 !important;
}

.rounded-r {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rounded-l {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.remove-compare-btn {
  height: auto !important;
}
.h-auto {
  height: auto !important;
}
/* Styling the toolbar */
.ql-toolbar {
  border: 1px solid #ccc;
  border-radius: 4px 4px 0px 0px;
  background-color: #f3f4f6;
}

/* Styling the editor content */
.ql-editor {
  font-size: 16px;
  line-height: 1.5;
  border-radius: 0px 0px 4px 4px;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 0px !important;
}